$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #2e365a;
  img {
    display: block;
    margin: 2rem auto;
    width: 90%;
    @media only screen and (min-width: $screen-sm) {
      width: 75%;
    }
    @media only screen and (min-width: $screen-lg) {
      width: 50%;
    }
    @media only screen and (min-width: $screen-xl) {
      width: 33%;
    }
  }
  p {
    padding-top: 4rem;
    text-align: center;
    color: #eee;
    font-weight: bold;
  }
}

